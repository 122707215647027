import React from "react";
import Phantom from "../assets/phantom.png";
import Solana from "../assets/solana.png";
import Raydium from "../assets/raydium.png";
import Logo from "../assets/logo-white.png";
import { ReactComponent as LinkSVG } from "../assets/link.svg";
import { ReactComponent as Copy } from "../assets/copy.svg";
import Button from "./Button";
import Background from "./Background";

const activity = [
  {
    id: 1,
    title: "Get Phantom wallet",
    imageUrl: Phantom,
    description:
      "You can download Phantom wallet using the link below. If you already have a wallet, you can skip this step.",
    button: (
      <Button
        label="get phantom"
        onClick={() => window.open("https://phantom.app/", "_blank")}
      >
        <LinkSVG />
        Get Phantom
      </Button>
    ),
  },
  {
    id: 2,
    title: "Buy $SOL from an exchange and transfer to your wallet",
    imageUrl: Solana,
    description:
      "Buy $SOL from any exchange you like. Once you have $SOL, transfer it to your Phantom wallet. If you already have $SOL in your wallet, you can skip this step.",
  },
  {
    id: 3,
    title: "Swap $SOL for $BULL on Raydium",
    imageUrl: Raydium,
    description:
      "You can swap $SOL for $BULL on Raydium using the link below. You can also copy $BULL contract address and add it to Raydium manually.",
    button: (
      <div className="flex items-start gap-4 sm:flex-row flex-col">
        <Button
          label="buy on raydium"
          onClick={() =>
            window.open(
              "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=AooXVDuftmaqvZpgA3DYsqSGCA4GgwtwSpe6yYdJMDb&fixed=in",
              "_blank"
            )
          }
        >
          <LinkSVG />
          Buy on Raydium
        </Button>
        <Button
          label="copy contract address"
          onClick={() =>
            navigator.clipboard.writeText(
              "AooXVDuftmaqvZpgA3DYsqSGCA4GgwtwSpe6yYdJMDb"
            )
          }
        >
          <Copy />
          Contract Address
        </Button>
      </div>
    ),
  },
];

const HowToBuy = () => (
  <Background showTopBackground={false}>
    <section className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8">
      <div className="flex items-center justify-between gap-4 flex-col-reverse sm:flex-row">
        <div className="flex items-start flex-col text-left">
          <h1 className="flex items-center mb-6 gap-4 text-5xl font-bold sm:text-5xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-7xl">
            How to buy $BULL
          </h1>
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              {activity.map((activityItem, activityItemIdx) => (
                <li key={activityItem.id}>
                  <div className="relative pb-8">
                    {activityItemIdx !== activity.length - 1 ? (
                      <span
                        className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-white"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-7">
                      <>
                        <div className="relative">
                          <img
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-white ring-8 ring-white"
                            src={activityItem.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-xl">
                              <p className="font-bold white">
                                {activityItem.title}
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 text-base text-white">
                            <p>{activityItem.description}</p>
                          </div>
                          {activityItem.button && (
                            <div className="mt-3">{activityItem.button}</div>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <img
          src={Logo}
          alt="bull-coin"
          className="w-96 sm:w-80 md:w-96 lg:w-96 xl:w-96 2xl:w-96 lg:block md:block"
        />
      </div>
    </section>
  </Background>
);

export default HowToBuy;
