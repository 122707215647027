import React from "react";
import Background from "./Background";
import Lambo from "../assets/lambo.png";
import RedBull from "../assets/redbull.png";
import ChicagoBull from "../assets/chicagobull.png";

const Affiliate = () => (
  <Background showTopBackground={false}>
    <h1 className="flex items-center gap-4 text-4xl font-bold mb-3 sm:text-4xl md:text-4xl lg:text-4xl">
      <span className="text-red-400 text-lg">not</span> Affiliated With
    </h1>
    {/* <section className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8"> */}
    <div className="flex items-center justify-between gap-4 flex-col sm:flex-row">
      <img src={Lambo} alt="lambo" className="w-52" />
      <img src={RedBull} alt="redbull" className="w-52" />
      <img src={ChicagoBull} alt="chicago" className="w-52" />
    </div>
    {/* </section> */}
  </Background>
);

export default Affiliate;
