import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import Button from "./Button";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 10px;
  & img {
    width: 220px;
  }
`;

const Header = () => (
  <nav className="flex items-center justify-between">
    <LogoContainer>
      <img src={Logo} alt="Logo" />
    </LogoContainer>
    <Button
      onClick={() =>
        window.open(
          "https://birdeye.so/token/AooXVDuftmaqvZpgA3DYsqSGCA4GgwtwSpe6yYdJMDb?chain=solana/#",
          "_blank"
        )
      }
      label="BUY $BULL"
      className="text-xl"
    >
      BUY $BULL
    </Button>
  </nav>
);

export default Header;
