import React from "react";
import Title from "../assets/title-left.png";
import Logo from "../assets/the-bulls.png";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import { ReactComponent as LinkExternal } from "../assets/link-external.svg";
import Button from "./Button";
import Background from "./Background";

const Intro = () => (
  <Background>
    <main className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8">
      <div className="flex items-center justify-between flex-col-reverse sm:flex-row">
        <div className="flex items-start flex-col text-left">
          <h1 className="flex items-center gap-4 text-3xl font-bold mb-2 sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl">
            Welcome to the
          </h1>
          <img
            src={Title}
            alt="Logo"
            className="w-[36rem] sm:w-[24rem] md:w-[36rem] lg:w-[36rem] xl:w-[36rem] 2xl:w-[36rem]"
          />
          <p className="text-2xl mt-4">Where Bulls become Billionaires</p>
          <p className="text-2xl mt-2">
            Bullion Coin – Your Ticket to the Crypto Elite!
          </p>
          <p className="text-2xl mt-2 mb-6 sm:mb-8 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16">
            Only for the Bullish!
          </p>
          <div className="flex items-center gap-4">
            <Button
              label="buy on raydium"
              onClick={() =>
                window.open(
                  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN&fixed=in",
                  "_blank"
                )
              }
              hideLabelOnMobile
            >
              <LinkExternal /> Buy on Raydium
            </Button>
            <Button
              label="telegram"
              onClick={() =>
                window.open("https://t.me/+evHf6eurUcY1Yjgx", "_blank")
              }
              hideLabelOnMobile
            >
              <TelegramIcon /> <span>Learn More</span>
            </Button>
          </div>
        </div>
        <img
          src={Logo}
          alt="logo"
          className="w-96 sm:w-80 md:w-96 lg:w-96 xl:w-96 2xl:w-96 lg:block md:block"
        />
      </div>
    </main>
  </Background>
);

export default Intro;
