import React from "react";
import BullPower from "../assets/bull-power.png";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import Button from "./Button";
import Background from "./Background";

const About = () => (
  <Background showTopBackground={false}>
    <section className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8">
      <div className="flex items-center justify-between gap-4">
        <img
          src={BullPower}
          alt="Simba"
          className="w-96 sm:w-80 md:w-96 lg:w-96 xl:w-96 2xl:w-96 hidden lg:block md:block"
        />
        <div className="flex items-start flex-col text-left">
          <h1 className="flex items-center gap-4 text-5xl font-bold mb-2 sm:text-5xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-7xl">
            About $BULL
          </h1>
          <h3 className="flex items-center gap-4 text-3xl font-bold mb-2 sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl">
            The Coin of the Elite
          </h3>
          <p className="text-2xl mt-4 mb-4">
            Step into a world of opulence with Bullion Coin – the epitome of
            luxury in the cryptocurrency sphere. Picture yourself cruising in
            your virtual Lamborghini, sipping champagne with the crypto elite –
            that's the lifestyle Bullion Coin promises. Join our exclusive
            Bullionaires community for a life of extravagance and
            sophistication. Elevate your status, indulge in decadence, and
            embrace luxury with Bullion Coin.
          </p>
          <p className="text-2xl mb-6 sm:mb-8 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16">
            Join the Bullionaires community today.
          </p>
          <div className="flex items-center gap-4">
            <Button
              label="telegram"
              onClick={() =>
                window.open("https://t.me/+evHf6eurUcY1Yjgx", "_blank")
              }
            >
              <TelegramIcon /> <span>Telegram</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  </Background>
);

export default About;
