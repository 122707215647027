import React from "react";
import { ReactComponent as Coins } from "../assets/coins.svg";
import { ReactComponent as CopySVG } from "../assets/copy.svg";
import Background from "./Background";
import { toast } from "react-toastify";

const stats = [
  { id: 1, name: "Symbol", value: "$BULL" },
  { id: 1, name: "Total Supply", value: "1 billion" },
  { id: 2, name: "Tax", value: "0/0" },
];

export default function Tokenomics() {
  const notify = () => toast("Contract address copied to clipboard!");
  return (
    <Background showTopBackground={false}>
      <section className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8">
        <h1 className="flex items-center text-5xl font-bold mb-6 sm:text-5xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-7xl">
          Tokenomics <Coins fill="white" className="w-16 h-16 ml-2" />
        </h1>
        <div className="mx-auto max-w-7xl">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-6 text-center lg:grid-cols-3">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="mx-auto flex sm:flex-col justify-between sm:justify-center gap-y-4 w-full rounded-3xl border-4 px-4 py-6"
              >
                <dt className="order-first text-2xl leading-7">{stat.name}</dt>
                <dd className="text-xl font-semibold sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
          <div className="mx-auto flex flex-col gap-y-4 mt-6 w-full rounded-3xl border-4 px-4 py-6 text-left sm:text-center">
            <dt className="order-first text-2xl leading-7">Token Address</dt>
            <dd className="text-2xl font-semibold sm:text-2xl">
              <p className="flex items-center justify-center">
                <span className="truncate">
                  AooXVDuftmaqvZpgA3DYsqSGCA4GgwtwSpe6yYdJMDb
                </span>
                <CopySVG
                  className="hover:scale-110 w-16 h-16 cursor-pointer ml-2"
                  fill="white"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "AooXVDuftmaqvZpgA3DYsqSGCA4GgwtwSpe6yYdJMDb"
                    );
                    notify();
                  }}
                />
              </p>
            </dd>
          </div>
        </div>
      </section>
    </Background>
  );
}
