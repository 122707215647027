import React from "react";
import Background from "./Background";
import SolanaBulls from "../assets/logo-white.png";
import { ReactComponent as X } from "../assets/x.svg";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import Button from "./Button";

const Socials = () => (
  <Background showTopBackground={false}>
    <section className="mx-auto max-w-5xl py-4 sm:py-6 lg:py-8">
      <div className="flex items-center justify-between gap-8">
        <img
          src={SolanaBulls}
          alt="Simba"
          className="w-6/12 sm:w-80 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 hidden lg:block md:block"
        />
        <div className="flex items-start flex-col text-left">
          <h1 className="flex items-center gap-4 text-5xl font-bold mb-6 sm:text-5xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-7xl">
            Join the Bullionaires Community
          </h1>
          <div className="flex items-center gap-4">
            <Button
              label="twitter"
              onClick={() =>
                window.open("https://twitter.com/BullionSolana", "_blank")
              }
              className="button"
              fillSVG="none"
            >
              <X /> Twitter
            </Button>
            <Button
              label="telegram"
              onClick={() =>
                window.open("https://t.me/+evHf6eurUcY1Yjgx", "_blank")
              }
              className="button"
            >
              <TelegramIcon /> Telegram
            </Button>
          </div>
        </div>
      </div>
    </section>
  </Background>
);

export default Socials;
