import React from "react";
import styled from "styled-components";
import { ReactComponent as X } from "../assets/x.svg";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import Button from "./Button";

const StyledFooter = styled.footer`
  border-radius: 80px 80px 0px 0px;
  border-top: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 64px;

  .container {
    max-width: 1276px;
    padding: 0 42px;
    margin: 0 auto;
    width: 100%;

    z-index: 2;
    padding-top: 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1280px) {
      max-width: 1280px;
    }

    @media (max-width: 1024px) {
      max-width: 1024px;
    }

    @media (max-width: 768px) {
      max-width: 768px;
    }

    @media (max-width: 640px) {
      max-width: 640px;
    }

    @media (max-width: 480px) {
      max-width: 480px;
    }

    .footer__content {
      max-width: 771px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 64px;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      line-height: 140%;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 480px) {
        font-size: 14px;
      }

      & .logo {
        width: 150px;

        @media (max-width: 768px) {
          width: 120px;
        }
        @media (max-width: 480px) {
          width: 100px;
        }
      }
    }

    .footer__other {
      width: 100%;
      padding: 16px 0;
      border-top: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 64px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 31px;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <div className="container">
      <div className="footer__content">
        <div>
          <p>
            Bullion Coin is a decentralized finance (DeFi) token that operates
            on the Solana blockchain. It is a community-driven project that aims
            to provide a luxury experience to its holders.
          </p>
          <div className="flex gap-4 items-center mt-6">
            <Button
              label="twitter"
              onClick={() =>
                window.open("https://twitter.com/BullionSolana", "_blank")
              }
              className="button"
              fillSVG="none"
            >
              <X /> Twitter
            </Button>
            <Button
              label="telegram"
              onClick={() =>
                window.open("https://t.me/+evHf6eurUcY1Yjgx", "_blank")
              }
              className="button"
            >
              <TelegramIcon /> Telegram
            </Button>
          </div>
        </div>
      </div>
      <div className="footer__other">
        <div className="">© 2024 Bullion Coin • All Rights Reserved</div>
      </div>
    </div>
  </StyledFooter>
);

export default Footer;
