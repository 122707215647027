import React from "react";
import styled from "styled-components";

const StyledButton = styled.button<{
  $hideLabelOnMobile: boolean;
  fillSVG: string;
}>`
  padding: 8px 10px;
  text-decoration: none;
  color: #fff;
  z-index: 1;
  background: transparent;
  border-radius: 10px;
  border-width: 0;
  font-weight: 700;
  font-size: 20px;
  transition: transform 0.4s ease;
  cursor: pointer;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & svg,
  img {
    width: 24px;
    height: 24px;
    fill: ${({ fillSVG }) => fillSVG};
  }

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    padding: 10px 10px;
    font-size: 14px;
    gap: 5px;
    ${({ $hideLabelOnMobile }) =>
      $hideLabelOnMobile && "span { display: none; }"}
  }
`;

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  type?: "button" | "submit" | "reset";
  label?: string;
  className?: string;
  hideLabelOnMobile?: boolean;
  fillSVG?: string;
}

const Button = ({
  children,
  onClick,
  type = "button",
  label,
  className,
  hideLabelOnMobile = false,
  fillSVG = "white",
}: Props) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      aria-label={label}
      className={className}
      $hideLabelOnMobile={hideLabelOnMobile}
      fillSVG={fillSVG}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
