import React from "react";
import Header from "./components/Header";
import "./App.css";
import Intro from "./components/Intro";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";
import Socials from "./components/Socials";
import HowToBuy from "./components/HowToBuy";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Affiliate from "./components/Affiliate";

function App() {
  return (
    <>
      <div className="mx-auto max-w-6xl px-4 mt-3 sm:px-3 lg:px-4">
        <Header />
        <div className="relative isolate pt-6">
          <Intro />
          <About />
          <HowToBuy />
          <Tokenomics />
          <Socials />
          <Affiliate />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
